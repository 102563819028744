import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import FlexBand from 'flexband'
import moment from 'moment'
import _ from 'lodash'
import TooltipWrapper from '../../UI/popover-wrapper'
import TooltipModal from '../../../components/search/table/tooltip-modal'
import Filter from '../../shared/components/widget-filter'
import Spinner from '../../UI/spinner'
import ProtectionWidget from '../../shared/components/widget-protection'
import UserPhoto from '../user-photo'
import PopoverWrapper from '../../UI/popover-wrapper'
import LoginLink from '../../navigation/login-link'
import dict from '../../shared/dictionaries'
import './styles.scss'
import { ProfileMiniProps, IFilterItem, ProfileMiniState } from './types'
import { IProfile } from '../../../store/reducers/types'
import { canDo } from '../../../store/utils'
import { getAccessPoint } from '../../../store/actions'
import Rating from '../../UI/star-rating'
import DatePicker from '../../UI/date-picker'
import fetch from '../../shared/fetch'
import { getAccessStatusDate } from '../../shared/utils'
import SendMessagePopup from './send-message-popup'
import { scrollToElement } from '../../../store/utils'
import GtuGtc from "../../gtu-gtc-handler"
import { confirmAlert } from 'react-confirm-alert'
import { toastr } from 'react-redux-toastr'

const STYLE_BASE = 'profile-mini_'

export default class ProfileMini extends Component<ProfileMiniProps, ProfileMiniState> {
  state: ProfileMiniState = {
    filterBar: {},
    rating: 3,
    reviewCount: 3,
    showMessagePopup: false,
    isAdmin: false,
    dateRegistered: null,
    showEditDatePanel: false,
    showEditButton: true,
    showAddReviewUrl: false,
    profileUpdated: {},
    userIsBlocked: false,
    showConfirm: false,
  }

  componentDidMount() {
    const { getGlobalUserRating, customUserId, getGlobalCanUserAddReview, userId, isAuth } = this.props

    const currentUserId = customUserId || userId
    if (currentUserId) {
      this.getProfileUpdated(currentUserId)
      fetch(`/user/getUserRating?userId=${currentUserId}`).then(res => {
        getGlobalUserRating(res.data)
      })

      isAuth && fetch(`/user/getReviewQuota/${currentUserId}`).then(res => {
        getGlobalCanUserAddReview(res.data.reviewQuota > 0)
      })



      this.setFilterBar()
      isAuth && this.getUserQuota(currentUserId)
      this.getUserProfile()
      this.getUserBlockedInfo(currentUserId)
    }

  }
  getProfileUpdated = (currentUserId) => {
    fetch(`/auth/getProfile/${currentUserId}`).then(res => {
      this.setState({ profileUpdated: res.profile })
    })
  }

  getUserQuota = (currentUserId) => {
    fetch('/user/getReviewQuota/' + currentUserId).then(res => {
      if (res.data.reviewQuota) {
        this.setState({ showAddReviewUrl: true })
      }
    })
  }

  getUserBlockedInfo = (userId) => {
    fetch(`/auth/getProfile/${userId}`).then(res => {
      const userIsBlocked = res.profile.activeStat === "B" || res.profile.activeStat === "D"
      this.setState({ userIsBlocked })
    })
  }

  getUserProfile = () => {
    const { customUserId, getProfile, userId } = this.props
    const searchUserId = customUserId || userId || 0
    if (searchUserId) {
      getProfile(searchUserId, profile => {
        const { dateRegistered } = profile
        const items = this.generateFilterItems(profile)
        const [{ value: selectedKey }] = items || [{}]
        this.setState(prevState => ({
          dateRegistered,
          filterBar: {
            ...prevState.filterBar,
            items,
            selectedKey,
          },
        }))
      })

      fetch(`/user/getUserRating?userId=${searchUserId}`)
        .then(res => {
          const { rating, reviewCount } = res.data

          this.setState({ reviewCount, rating })
        })
        .catch(e => console.error(e))

      getAccessPoint('admin').then(status => {
        this.setState({ isAdmin: status === 'permitted' })
      })
    }
  }

  componentDidUpdate(prevProps: ProfileMiniProps) {
    if (!_.isEqual(prevProps.profile, this.props.profile)) {
      this.setFilterBar()
    }

    if (prevProps.customUserId !== this.props.customUserId) {
      this.getUserProfile()
      this.getProfileUpdated(this.props.customUserId)
    }
  }

  setFilterBar = () => {
    const items = this.generateFilterItems(this.props.profile)

    this.setState({
      filterBar: {
        items,
        selectedKey: items && items[0]?.value,
        onClick: filterKey => {
          const { filterBar: filterBarOld } = this.state
          if (filterKey !== filterBarOld.selectedKey) {
            const filterBar = { ...filterBarOld }
            filterBar.selectedKey = filterKey
            this.setState({ filterBar })
          }
        },
      },
    })
  }

  componentWillUnmount() {
    // this.props.resetProfile()
  }

  generateFilterItems = (profile: IProfile): IFilterItem[] => {
    const {
      skype,
      viber,
      whatsup,
      telegram,
      fbMessenger,
      linkedin,
      phone1,
      additionalInfo,
      email: profileEmail,
    } = profile.contactDetails
    const { t, email, customUserId } = this.props
    const addFilterItem = (key, value) => value && { name: t(key), value: key }
    return [
      addFilterItem('phone', phone1),
      addFilterItem('skype', skype),
      addFilterItem('viber', viber),
      addFilterItem('whatsup', whatsup),
      addFilterItem('telegram', telegram),
      addFilterItem('fbMessenger', fbMessenger),
      addFilterItem('linkedin', linkedin),
      addFilterItem('email', customUserId ? profileEmail : email),
      addFilterItem('additionalInfo', additionalInfo),
    ].filter(x => !!x)
  }

  getFilterValue = (filterKey: string) => {
    const {
      profile: { contactDetails },
      t
    } = this.props
    const { profileUpdated } = this.state
    const value = contactDetails[filterKey]

    let href = value
    if (filterKey === 'email') href = `mailto:${value}`
    if (filterKey === 'skype') href = `skype:${value}`
    if (filterKey === 'whatsup') href = `https://wa.me/${value.slice(1).split(' ').join('')}`
    if (filterKey === 'telegram') href = `https://telegram.me/${value}`
    if (filterKey === 'viber') href = `viber://chat?number=${value.slice(1).split(' ').join('')}`

    const withTargetList = ['whatsup', 'linkedin', 'fbMessenger', 'telegram']
    const withTarget: React.AnchorHTMLAttributes<HTMLAnchorElement> = {}
    if (withTargetList.some(el => el === filterKey)) {
      withTarget.target = '_blank'
      withTarget.rel = 'noopener noreferrer'
    }

    if (filterKey === 'phone') {
      const { phone1, phone2 } = contactDetails
      return (
        <Fragment>
          <a href={`tel:${phone1}`} className="text">
            {phone1}
          </a>
          {phone2 && (
            <a href={`tel:${phone2}`} className="text">
              {phone2}
            </a>
          )}
        </Fragment>
      )
    } else if (filterKey === 'additionalInfo') {
      return <span className="text">{value}</span>
    } else if (filterKey === 'email' && profileUpdated?.showEmail !== 1) {
      return <span className="text">{t('email.is.unvisible')}</span>
    } else {
      return (
        <a href={href} {...withTarget} className="text">
          {value}
        </a>
      )
    }
  }

  getWordEnding = (numberBase: number) => {
    const { langId } = this.props
    const lastDigit = numberBase % 10

    if (langId === 1) {
      if (lastDigit === 1) {
        return 'а'
      } else {
        return 'ов'
      }
    } else {
      if (lastDigit === 1) {
        return ''
      } else {
        return 's'
      }
    }
  }

  renderBlockPopover = () => {
    const { cargoBlocked, transportBlocked, onBlockChange, t } = this.props
    return (
      <div>
        <div>{t('user.add.to.block.list')}</div>

        <span className="col-gray">{t('user.block.description')}</span>
        <FlexBand direction="column">
          <input
            id="cargoBlocked"
            type="checkbox"
            checked={cargoBlocked}
            onChange={onBlockChange}
          />
          <label htmlFor="cargoBlocked">{t('cargo.offers')}</label>
          <input
            id="transportBlocked"
            type="checkbox"
            checked={transportBlocked}
            onChange={onBlockChange}
          />
          <label htmlFor="transportBlocked">{t('transport.offers')}</label>
        </FlexBand>
      </div>
    )
  }

  showMessagePopup = () => {
    const {
      userId,
      customUserId,
      profile: {
        userInfo: { firstName, lastName },
      },
    } = this.props
    return (
      <SendMessagePopup
        chatPartnerId={customUserId}
        userId={userId}
        opponentName={firstName + ' ' + lastName}
        hidePopup={() => this.setState({ showMessagePopup: false })}
      />
    )
  }

  changeDatePanel = () => {
    const { showEditDatePanel } = this.state
    this.setState({ showEditDatePanel: !showEditDatePanel })
  }

  onChangeDate = (dateRegistered: Date) => {
    this.setState({ dateRegistered })
  }

  addReview = () => {
    const { focusAddReview } = this.props
    focusAddReview()
    setTimeout(() => {
      scrollToElement('addReview')
    }, 1000);
  }

  hideConfirm = () => {
    this.setState({ showConfirm: false })
  }

  clickBlock = () => {
    const { t, customUserId } = this.props
    const { userIsBlocked } = this.state
    // this.setState({ showConfirm: true })
    confirmAlert({
      title: t(!userIsBlocked ? 'block.sure' : 'unblock.sure'),
      message: (
        <span>
          {t(!userIsBlocked ? 'block.cannot.undone' : 'unblock.cannot.undone')}
        </span>
      ) as any,
      buttons: [
        {
          label: t(!userIsBlocked ? 'news.switch.disable' : "unblock.user"),
          onClick: () => {
            // const { userId } = this.props.match.params
            // this.hideConfirm()
            this.setState({ showConfirm: !this.state.showConfirm })
            fetch(`/user/${customUserId}/block/${!userIsBlocked}`, {
              method: 'POST',
            }).then(response => {
              toastr.success(t(!userIsBlocked ? 'block.success' : 'unblock.success'))
              this.getUserBlockedInfo(customUserId)
            }).catch((err) => {
              toastr.error(t("do.no.have.permissions"))
            })
          },
        },
        {
          label: t('button.close'),
          onClick: this.hideConfirm,
        },
      ],
      onClickOutside: this.hideConfirm,
      onKeypressEscape: this.hideConfirm,
    })
  }

  render() {
    const {
      t,
      loading,
      languages,
      profile,
      profile: {
        userInfo,
        userInfo: {
          firstName,
          lastName,
          languages: userLanguages,
          company: { companyId, companyName },
          dateRegistered,
          countryId,
          photo,
          accessStatus,
          accessStatusExpires,
          hasAdminNotices,
        },
      },
      closeModal,
      customUserId,
      isAuthenticated,
      permissions,
      userId,
      showFullInfo,
      currentLanguage,
      openReviews,
      focusAddReview,
      userChangeDateRegistration,
      langId,
      userReviewQuota,
      userCanAddReview,
      myProfile,
      isAuth,
      showActionIcons = false
    } = this.props
    const {
      filterBar,
      rating,
      reviewCount,
      isAdmin,
      showMessagePopup,
      showEditDatePanel,
      showEditButton,
      showAddReviewUrl,
      profileUpdated,
      profileUpdated: {
        positionInCompany,
        isCompanyAdmin,
        gtu,
        gender,
        statusId
      },
      userIsBlocked
    } = this.state

    const langs = languages.filter(x => userLanguages?.split(',').includes(String(x.value)))

    const canEditDate = canDo(permissions, 'dateRegistration', 'edit')
    const user = customUserId || userId
    const dateToday = moment().endOf('day')
    if (!isAuthenticated && !showFullInfo) {
      return (
        <ProtectionWidget>
          <div>
            <LoginLink>{t('ads.login')}</LoginLink> {t('ads.or')}
            <Link to="/auth/registration"> {t('ads.register')}</Link>,<br />
            {t('ads.in.order.to.see')}.
          </div>
        </ProtectionWidget>
      )
    }

    const countryList = dict.countries(langId)
    let coutryAbbr
    let coutryFullName

    if (countryList.items) {
      const coutryAbbrArr = countryList.items.find(
        oneCountry => oneCountry.value === Number(countryId)
      )
      if (coutryAbbrArr) {
        coutryAbbr = coutryAbbrArr.iso
        coutryFullName = coutryAbbrArr.name
      }
    }

    const position = dict.positionsInCompany(langId)
    let positionInCompanyFinish
    if (position.items) {

      positionInCompanyFinish = position.items.find(
        positionId => positionId.value === Number(positionInCompany)
      )
    }

    const title = !showFullInfo
      ? t('goto.user.details')
      : showFullInfo && userId === user
        ? t('goto.edit.profile')
        : ''
    const to = user !== userId ? `/user/details/${user}` : '/user/profile'

    const gtugtcProps = {
      code: gtu,
      type: "user",
      id: user,
      refresh: () => this.getUserProfile()
    }
    const margin = userId === customUserId ? "0px" : "0px 10px"

    return (
      <div className={classNames('panel panel-has-user', `${STYLE_BASE}wrapper`)}>
        <div className="panel-offer-user">
          {loading ? (
            <div className={classNames(`${STYLE_BASE}info`)}>
              <Spinner />
            </div>
          ) : (
            <Fragment>
              <div className="user-info-header">
                <div className="user-info-header-cell">
                  <div className="img-container">
                    <UserPhoto customUserId={customUserId} photo={photo} gender={gender} />
                  </div>
                </div>
                <div className="user-info-header-cell user-info-header-name">
                  <div className="additional-container">
                    <div className="user-header">
                      <Link {...{ to }} className="name" {...{ title }} onClick={closeModal}>
                        {`${firstName} ${lastName}`}
                      </Link>
                      <div className={'additional-info'}>
                        {coutryFullName && (
                          <span className="meta country">
                            <TooltipWrapper text={coutryFullName} hover>
                              ({coutryAbbr})
                            </TooltipWrapper>
                          </span>
                        )}
                        <GtuGtc {...gtugtcProps} />
                        {userIsBlocked && <span className="user-is-blocked">
                          {t('user.is.blocked')}
                        </span>}
                      </div>
                    </div>

                    {isAuth && showActionIcons && <div className="settings">
                      {hasAdminNotices && (isAdmin || userId === user) && (
                        <TooltipWrapper text={t('popover.claims')} hover>
                          <Link to={`/user/notices/${user}`} className="settings-icon">
                            <i className="fa fa-list-alt" />
                          </Link>
                        </TooltipWrapper>
                      )}
                      {isAdmin && (
                        <TooltipWrapper text={t(userIsBlocked ? 'user.set.unblock' : 'user.set.block')} hover>
                          <i
                            className={`fa fa-${userIsBlocked ? "unlock" : "lock"} block-user`}
                            style={{ margin: "0px 5px" }}
                            onClick={this.clickBlock}
                          />
                        </TooltipWrapper>
                      )}
                      {isAdmin && (
                        <TooltipWrapper text={t('popover.access.points')} hover>
                          <Link
                            to={`/company/access/user/${user}`}
                            className="settings-icon"
                            onClick={closeModal}
                          >
                            <i className="fa fa-cog button-access" style={{ margin }} />
                          </Link>
                        </TooltipWrapper>
                      )}
                      {showFullInfo && userId != customUserId && (
                        <div>
                          <PopoverWrapper
                            placement="bottom"
                            innerStyle={{ maxWidth: '400px' }} // here inline styles are required .. because only in this place you need to interrupt common styles in a fixed width .. here the tooltip should be full width
                            text={this.renderBlockPopover()}
                          >
                            <TooltipWrapper text={t('popover.ban.list')} hover>
                              <i className="fa fa-ban button-ban" />
                            </TooltipWrapper>
                          </PopoverWrapper>
                          {showMessagePopup && this.showMessagePopup()}
                          <TooltipWrapper text={t('popover.send.message')} hover>
                            <i
                              className="fa fa-envelope"
                              onClick={() => this.setState({ showMessagePopup: true })}
                            />
                          </TooltipWrapper>
                        </div>
                      )}
                    </div>}
                    <div className="clear" />
                  </div>
                  {positionInCompanyFinish?.name &&
                    <div className="name-sm">
                      {positionInCompanyFinish.name} {isCompanyAdmin > 0 && `, ${t('administrator')}`}
                    </div>
                  }

                  <div className="language-user">
                    <span className="meta">{t('language') + ':'}</span>
                    <ul className="list-flags">
                      {langs.map((x, j) => (
                        <li key={j} title={x.fullName}>
                          <span className={classNames('flag flag-icon', x.flag)} />
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="has-tooltip">
                    {statusId === "2" && <Link
                      to={'/company/item/' + companyId}
                      className="name-sm has-tooltip"
                      onClick={closeModal}
                    >
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <TooltipWrapper
                        text={
                          <TooltipModal
                            companyName={companyName}
                            companyId={companyId}
                            userId={0}
                          />
                        }
                        hover
                        innerClassName="full-tooltip"
                        disabled={false}
                      >
                        {companyName}
                      </TooltipWrapper>
                    </Link>}
                  </div>
                </div>
                <div className="user-info-header-cell user-info-header-link">
                  <Link to="/user/profile" className="link-iconed" onClick={closeModal}>
                    {!customUserId && (
                      <Fragment>
                        <i className="fa fa-pencil" />
                        <span>{t('profile.edit')}</span>
                      </Fragment>
                    )}
                  </Link>
                </div>
              </div>
              {showFullInfo && (
                <FlexBand className="full-user-info" align="center" justify="space-between">
                  <FlexBand className="user-data">
                    <FlexBand className="user-data-column" direction="column">
                      <span className="meta">{t('registration.date')}</span>
                      <span id="registration-date">
                        {showEditDatePanel ? (
                          <span className="date-registered-panel">
                            <DatePicker
                              initialValue={dateRegistered}
                              maxDate={dateToday}
                              onChangeDate={this.onChangeDate}
                              onBlur={() => this.setState({ showEditButton: true })}
                              onFocus={() => this.setState({ showEditButton: false })}
                            />
                            {showEditButton && (
                              <i
                                className="fa fa-check-square col-green"
                                onClick={() =>
                                  userChangeDateRegistration(user, this.state.dateRegistered)
                                }
                              />
                            )}
                          </span>
                        ) : (
                          <span className="date-registered-line">
                            {new Date(dateRegistered).toLocaleDateString('ru-ru')}
                          </span>
                        )}
                        {canEditDate && (
                          <i className="fa fa-pencil col-blue" onClick={this.changeDatePanel} />
                        )}
                      </span>
                    </FlexBand>
                    <FlexBand direction="column" className="user-data-column">
                      <span className="meta">{t('status')}</span>
                      <Link to="/about-us/prices" className="access-status">
                        {accessStatus ? `${accessStatus} user` : null}
                      </Link>
                      <span>{getAccessStatusDate(accessStatusExpires, t('to') + ' ')}</span>
                    </FlexBand>
                    <FlexBand direction="column">
                      <span className="meta">{t('user.rating')}</span>
                      {userReviewQuota?.rating
                        ? [
                          <div key={0}>
                            <Rating averageRating={userReviewQuota.rating} />{' '}
                            {userReviewQuota.rating.toFixed(2)}
                          </div>,
                          <Link to="#" key={1} onClick={openReviews}>
                            {t('based.on.review(s)', {
                              reviewCount: userReviewQuota.reviewCount,
                              wordEnding: this.getWordEnding(userReviewQuota.reviewCount),
                            })}
                          </Link>,
                        ]
                        : t('not.enough.data')}
                    </FlexBand>
                  </FlexBand>


                  {isAuth && <>
                    {customUserId !== userId && showAddReviewUrl && userCanAddReview && myProfile.verifiedCompany > 0 ? (
                      <Link to="#" className="add-review" onClick={() => this.addReview()}>
                        {t('review.add')}
                      </Link>
                    ) : (
                      <TooltipWrapper text={!userCanAddReview ? t('add.review.already.added') : t('only.verif.admins.and.users')} hover>
                        <Link to="#" className="add-review">
                          {t('review.add')}
                        </Link>
                      </TooltipWrapper>
                    )}
                  </>}
                </FlexBand>
              )}
              <div className="user-info-content">
                <Filter {...filterBar} loadLanguages />

                <div className="panel-filter-content">
                  {this.getFilterValue(filterBar.selectedKey)}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}
