import React, { Component } from 'react'
import { match } from 'react-router'
import DatePicker from '../../UI/date-picker'
import { confirmAlert } from 'react-confirm-alert'
import moment from "moment"
import classNames from "classnames"
import Spinner from '../../UI/spinner'

import './styles.scss'

interface AccessDatesState {
  dateBegin: any,
  dateEnd: any,
  isFetching: boolean
}

interface AccessDatesProps extends PermissionInjectProps, ComponentBaseProps {
  match: match<{ userId }>,
  access: any,
  selectedKey: any,
  handleSubmit: any
}
export default class AccessDates extends Component<AccessDatesProps, AccessDatesState> {
  constructor(props) {
    super(props)
    this.state = {
      dateBegin: null,
      dateEnd: null,
      isFetching: false,
    }
  }

  onChangeDateBegin = (dateBegin) => {
    this.setState({ dateBegin })
  }
  onChangeDateEnd = (dateEnd) => {
    this.setState({ dateEnd })
  }

  updateAccesGroups = (idAccess, value, dateEnd, dateBegin) => {
    const { handleSubmit } = this.props

    const now = new Date();

    handleSubmit(
      idAccess, 
      value, 
      dateBegin = {
        day: moment(dateBegin)?.date() || now.getDate(),
        month: (moment(dateBegin)?.month() || now.getMonth()) + 1,
        year: moment(dateBegin)?.year() || now.getFullYear(),
      },
      dateEnd = {
        day: moment(dateEnd)?.date() || now.getDate(),
        month: (moment(dateEnd)?.month() || now.getMonth()) + 1,
        year: moment(dateEnd)?.year() || now.getFullYear(),
      }
    )

    setTimeout(() => {
      this.setState({ isFetching: false })      
    }, 1000);
  }

  onAccessChange = access => {
    const { t, handleSubmit } = this.props
    const { dateEnd, dateBegin } = this.state
    const { idAccess, groupId, value } = access

    this.setState({ isFetching: true }, () => {
      if (idAccess === 1) {
        confirmAlert({
          title: t('access.admin'),
          message: t('access.admin.verification'),
          buttons: [
            {
              label: t('yes'),
              onClick: () => handleSubmit(idAccess, value, dateEnd, dateBegin),
            },
            {
              label: t('no'),
              onClick: () => { return true; },
            },
          ],
        })
      } else {
        this.updateAccesGroups(idAccess || groupId, value, dateEnd, dateBegin)
      }
    })

  }

  render() {
    const { access, selectedKey, t } = this.props
    const { dateBegin, dateEnd, isFetching } = this.state
    const disabled = selectedKey !== "accessGroups" ? false : !dateBegin || !dateEnd

    return (
      <div
        className={classNames({
          'tr-card': true,
          'mb-20': selectedKey === "accessGroups",
        })}
      >
        <div className="access-point-description">
          <div>{(access.idAccess || access.groupId) + '. ' + access.name}</div>
          <div className="input-container">
            {selectedKey === "accessGroups" ? <>
              <button
                type="button"
                disabled={access.value || Number(access.groupId) !== 1 ? !dateBegin || !dateEnd : false}
                className={classNames({
                  'button': true,
                  'bg-dark': !access.value || isFetching,
                  'disabled': Number(access.groupId) !== 1 ? !dateBegin || !dateEnd : false || isFetching,
                  'button-orange': access.value,
                })}
                onClick={() => this.onAccessChange(access)}
              >
                {access.value ? t('save.changes') : t("activate")}
                {isFetching && <Spinner />}
              </button>
            </> : <>
              <input
                type={selectedKey === 'accessPoints' ? 'checkbox' : 'radio'}
                name={access.name}
                value={access.name}
                id={`access_${access.name}`}
                checked={access.value}
                {...{ disabled }}
                onChange={() => this.onAccessChange(access)}
              />
              <label htmlFor={`access_${access.name}`} className="loading-place-checkbox" />
            </>}
          </div>
        </div>
        {selectedKey === "accessGroups" && Number(access.groupId) !== 1 && <div className="access-dates">
          <div className="banner-line-description">
            <div className="date-label">{t('activation.from')}</div>
            <div className="banner-input-field date-field">
              <DatePicker
                initialValue={dateBegin || access.dateBegin}
                onChangeDate={this.onChangeDateBegin}
                setToLocaleDateString
              />
            </div>
          </div>
          <div className="banner-line-description">
            <div className="date-label">{t('activation.to')}</div>
            <div className="banner-input-field date-field">
              {/* {dateBegin} */}
              <DatePicker
                initialValue={dateEnd || access.dateEnd}
                onChangeDate={this.onChangeDateEnd}
                setToLocaleDateString
              />
            </div>
          </div>
        </div>}
      </div>
    );
  }
}