import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import FlexBand from 'flexband'
import { Field } from 'redux-form'
import { Link } from 'react-router-dom'

import ChangePasswordForm from '../../auth/change-password'
import ChangeEmailForm from '../../auth/change-email'
import { ModalConsumer } from '../../../context/modal-context'
import { required, email as emailValidator } from '../../shared/form-validators'
import PopoverWrapper from '../../UI/popover-wrapper'
import Dropdown from '../../UI/dropdown'
import Checkbox from '../../UI/checkbox'
import CheckboxList from '../../UI/checkbox-list'
import ImageUploadField from '../../UI/image-upload-field'
import { PROFILE_MODE_REGISTRATION, PROFILE_MODE_EDIT } from '../../../store/constants'
import FieldBase from '../../UI/field-base'
import renderPhone from '../../UI/util/render-phone'
import dict from '../../shared/dictionaries'
import AlertBanner from '../../UI/alert-banner'
import { LANGUAGES_MAX_COUNT } from '../user-profile-constants'
import fetch from '../../shared/fetch'
import classnames from "classnames"
import TooltipWrapper from '../../UI/popover-wrapper'
import { hideScrollBar } from "../../../store/utils"

import './profile.scss'

const STYLE_BASE = 'profile_'

const contactDataFields = [
  {
    t: 'phone',
    props: {
      name: 'phone1',
      component: renderPhone,
      type: 'text',
      placeholder: '+',
      validate: required,
    },
  },
  {
    t: 'phone',
    props: {
      name: 'phone2',
      component: renderPhone,
      type: 'text',
      placeholder: '+',
    },
  },
  {
    t: 'skype',
    props: {
      name: 'skype',
      component: FieldBase,
      type: 'text',
      maxLength: 100,
    },
  },
  {
    t: 'viber',
    props: {
      name: 'viber',
      component: renderPhone,
      type: 'text',
      placeholder: '+',
    },
  },
  {
    t: 'whatsup',
    props: {
      name: 'whatsup',
      component: renderPhone,
      type: 'text',
      placeholder: '+',
    },
  },
  {
    t: 'telegram',
    props: {
      name: 'telegram',
      component: FieldBase,
      type: 'text',
    },
  },
  {
    t: 'fbMessenger',
    props: {
      name: 'fbMessenger',
      component: FieldBase,
      type: 'text',
    },
  },
  {
    t: 'linkedin',
    props: {
      name: 'linkedin',
      component: FieldBase,
      type: 'text',
    },
  },
]

export default class extends Component {
  state = {
    languages: [],
    countSelectedLaguages: 0,
    contactErrors: {
      phone1: false,
      phone2: false,
      fax: false,
      viber: false,
      whatsup: false,
      telegram: false,
    }
  }

  componentDidMount() {
    const { initialize, initialValues, email, change, isAuthenticated } = this.props
    const { legacyPassword } = initialValues

    const { loading, handler, items } = dict.languages()
    const formatLangs = langs =>
      langs.map(x => ({
        ...x,
        name: (
          <Fragment>
            <span className={classNames('flag flag-icon', x.flag)} />
            {' ' + x.fullName}
          </Fragment>
        ),
        title: x.fullName,
      }))

    if (loading) {
      handler.then(data => {
        const { items } = data
        this.setState({ languages: formatLangs(items) })
      })
    } else {
      this.setState({ languages: formatLangs(items) })
    }

    email && isAuthenticated && fetch(`/auth/personal-info`).then(res => {
      initialize({
        ...initialValues,
        phone1: res.info.phone1,
        phone2: res.info.phone2,
        skype: res.info.skype,
        viber: res.info.viber,
      })
    })

    this.setState({ countSelectedLaguages: initialValues.languages.length })
    if (legacyPassword) {
      // login modal closes 500ms
      setTimeout(() => this.showItemModal('password'), 600)
    }

    email && change('email', email)

    this.addPopover()
  }

  onErrorChange = (fieldName, errStatus) => {
    this.setState(prevState => ({
      contactErrors: { ...prevState.contactErrors, [fieldName]: errStatus },
    }))
  }

  getContactErrStatus = () => {
    let isErrs = false
    const { contactErrors } = this.state

    //eslint-disable-next-line
    for (let i in contactErrors) {
      if (contactErrors[i]) {
        isErrs = true

        break
      }
    }

    return isErrs
  }

  addPopover = () => {
    const { t } = this.props
    contactDataFields[0].props.popover = (
      <PopoverWrapper text={t('profile.phone.info')} placement="right">
        <i className="fa fa-question-circle col-blue profile_wrapper_icon" />
      </PopoverWrapper>
    )
  }

  showItemModal = entity => {
    const { legacyPassword } = this.props.initialValues
    const { showModalComponent, clearModal } = this.modalConsumer
    const modalItem =
      entity === 'email' ? (
        <ChangeEmailForm clearModal={clearModal} />
      ) : (
        <ChangePasswordForm clearModal={clearModal} isLegacyPassword={legacyPassword} />
      )
    hideScrollBar()
    showModalComponent(modalItem, {
      hideHeader: true,
      skipLayout: true,
      backdrop: !legacyPassword || 'static',
      keyboard: !legacyPassword,
      className: 'change-password-email-modal',
    })
  }

  setSelectedLanguages = (value) => {
    this.setState({ countSelectedLaguages: value })
  }

  render() {
    const { isAuthenticated, mode } = this.props
    if (!isAuthenticated && mode !== PROFILE_MODE_REGISTRATION) {
      return (
        <div className={classNames('panel panel-has-user', `${STYLE_BASE}wrapper`)}>
          <div className="panel-offer-user">Please login to view this component</div>
        </div>
      )
    }

    const {
      t,
      userId = '',
      username,
      handleSubmit,
      pristine,
      submitting,
      invalid,
      langId,
      additionalInfo,
      initialValues: { approved, legacyPassword },
      companyVerifiedInfo,
    } = this.props
    const { countSelectedLaguages } = this.state

    const folder = `profile/${userId}`
    const showAlertBanner = countSelectedLaguages > LANGUAGES_MAX_COUNT
    const isReRegistration = mode === PROFILE_MODE_EDIT && !approved
    const isRegistration = mode === PROFILE_MODE_REGISTRATION

    let disableEditing = false
    if ((companyVerifiedInfo?.isValid === 0 || companyVerifiedInfo?.isValid === 1) && companyVerifiedInfo?.isValid !== null) {
      disableEditing = true
    }

    return (
      <Fragment>
        <form className={classNames('panel-form panel-form-simple', `${STYLE_BASE}wrapper`)}>
          <div className="panel-content-row">
            <div className="panel-form-row">
              <div className="row">
                <div className="col-md-4 col-lg-5">
                  <h5 className="title-gray">{t('profile.registration.data')}</h5>
                  {!approved && (
                    <div className="registration-warning-panel">
                      <p className="text-error">
                        <i className="fa fa-exclamation-triangle" />
                        <span>{t('attention')}</span>
                      </p>
                      <p>
                        {isRegistration ? t('registration.warning') : t('reregistration.warning')}
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-md-12 col-lg-10">
                  <div className="panel-form-row row">
                    <div className="col-sm-8">
                      <label>{t('profile.login')}</label>
                      <input type="text" value={username} readOnly />
                    </div>
                    <div className="col-sm-8">
                      <div className="meta-aligned">
                        <span className="meta-gray">{t('profile.change.login.restriction')}</span>
                      </div>
                    </div>
                  </div>
                  {!isRegistration && (
                    <div className="panel-form-row row">
                      <div className="col-sm-8 mb-sm">
                        <label>{t('login.password')}</label>
                        <Link to="#" onClick={() => this.showItemModal('password')}>
                          {t('login.password.change')}
                        </Link>
                      </div>
                    </div>
                  )}
                  <div className="panel-form-row row">
                    <div className="col-sm-8">
                      <div className="clearfix">
                        <label htmlFor="lll3" className="fl-left">
                          {`Email`}
                        </label>
                      </div>
                      <PopoverWrapper
                        placement={'left'}
                        text={t('reregistration.email.tooltip')}
                        disabled={!isReRegistration}
                        focus={true}
                        isOpen={true}
                      >
                        <Field
                          name="email"
                          component={FieldBase}
                          type="email"
                          maxLength="100"
                          validate={[required, emailValidator]}
                          readOnly={!isReRegistration}
                          autoFocus={isReRegistration}
                        />
                      </PopoverWrapper>
                    </div>
                    <div className="col-sm-8">
                      <div className="meta-aligned">
                        <Field name="showEmail" component={Checkbox}>
                          {t('profile.show.email')}
                        </Field>
                      </div>
                    </div>
                  </div>
                  {approved ? (
                    <div className="panel-form-row row">
                      <div className="col-sm-8 mb-sm">
                        <Link to="#" onClick={() => this.showItemModal('email')}>
                          {t('login.email.change')}
                        </Link>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="panel-content-row">
            <div className="panel-form-row">
              <div className="row">
                <div className="col-md-4 col-lg-5">
                  <h5 className="title-gray">{t('profile.about.me')}</h5>
                </div>
                <div className="col-md-12 col-lg-10">
                  <div className="panel-form-row row">
                    <FlexBand>
                      <div style={{ paddingLeft: 4, marginRight: 8 }}>
                        <label htmlFor="lll4">{t('photo')}</label>
                        <Field
                          name="photo"
                          component={ImageUploadField}
                          className="photo-block photo-rounded"
                          folder={folder}
                        />
                      </div>
                      <div style={{ height: 155 }}>
                        <FlexBand justify="center" direction="column" style={{ height: '100%' }}>
                          <span className="meta-gray">{t('jpg.file.format')}</span>
                          <span className="meta-gray">5 MB</span>
                        </FlexBand>
                      </div>
                    </FlexBand>
                  </div>
                  <div className="panel-form-row row">
                    <div className="col-sm-8">
                      <label className="required-field">
                        {disableEditing && <div className="tooltip-verif-company">
                          <TooltipWrapper text={t('verification.in.progress')} hover>
                            <i className="fa fa-info-circle col-blue" />
                          </TooltipWrapper>
                        </div>}
                        {t('last-name')}
                      </label>
                      <Field
                        name="lastName"
                        component="input"
                        type="text"
                        disabled={disableEditing}
                        maxLength="100"
                        validate={[required]}
                        className={classnames({
                          'cursor-disabled': disableEditing
                        })}
                      />
                    </div>
                  </div>
                  <div className="panel-form-row row">
                    <div className="col-sm-8">
                      <label className="required-field">
                        {disableEditing && <div className="tooltip-verif-company">
                          <TooltipWrapper text={t('verification.in.progress')} hover>
                            <i className="fa fa-info-circle col-blue" />
                          </TooltipWrapper>
                        </div>}
                        {t('first-name')}
                      </label>
                      <Field
                        name="firstName"
                        component="input"
                        type="text"
                        disabled={disableEditing}
                        maxLength="100"
                        validate={[required]}
                        className={classnames({
                          'cursor-disabled': disableEditing
                        })}
                      />
                    </div>
                  </div>
                  <div className="panel-form-row row">
                    <div className="col-sm-8 col-lg-6 col-xl-4">
                      <label className="required-field">{t('gender')}</label>
                      <Field
                        name="gender"
                        component={Dropdown}
                        emptyOption={t('choose-gender')}
                        items={[
                          { value: 'male', name: t('male') },
                          { value: 'female', name: t('female') },
                        ]}
                        validate={required}
                      />
                    </div>
                  </div>
                  <div className="panel-form-row row">
                    <div className="col-sm-8 col-lg-6 col-xl-4">
                      <label className="required-field">{t('country')}</label>
                      <Field
                        name="countryId"
                        component={Dropdown}
                        items={dict.countries(langId)}
                        emptyOption={t('select.country')}
                        validate={required}
                        sortAlphabetic
                      />
                    </div>
                  </div>
                  <FlexBand wrap="nowrap" justify="flex-start">
                    <label className="required-field">{t('profile.languages')}</label>
                  </FlexBand>
                  <FlexBand wrap="wrap" directon="column" justify="flex-start">
                    <Field
                      name="languages"
                      component={CheckboxList}
                      options={this.state.languages}
                      itemClassName="checkbox-block"
                      validate={required}
                      countLanguages={e => this.setSelectedLanguages(e)}
                    />
                  </FlexBand>
                  {showAlertBanner && <AlertBanner text={t('alert.message')} />}
                </div>
              </div>
            </div>
          </div>
          <div className="panel-content-row">
            <div className="panel-form-row">
              <div className="row">
                <div className="col-md-4 col-lg-5">
                  <h5 className="title-gray">{t('profile.contact.details')}</h5>
                  <div className="row">
                    <div className="col-sm-11 col-md-16 col-lg-11">
                      <div className="panel-description">
                        <p className="text-error">
                          <i className="fa fa-exclamation-triangle" />
                          <span>{t('attention')}</span>
                        </p>
                        <p>{t('profile.company.disclaimer.1')}</p>
                        <p>{t('profile.company.disclaimer.2')}</p>
                      </div>
                    </div>
                  </div>
                  <div className={`${STYLE_BASE}info-required-field`}>
                    <span className="color-red">* </span>
                    {t('required.field')}
                  </div>
                </div>
                <div className="col-md-12 col-lg-10">
                  {contactDataFields.map((x, j) => {
                    const isRequired = !!x.props.validate && x.props.validate === required
                    return (
                      <div key={j} className="panel-form-row row">
                        <div className="col-sm-8 col-lg-6 col-xl-4">
                          <label
                            className={classNames({
                              ['required-field']: isRequired,
                            })}
                          >
                            {t(x.t)}
                            {` `}
                            {!isRequired && <span className="meta-gray">({t('optional')})</span>}
                          </label>
                          <Field
                            {...x.props}
                            formatError={this.state.contactErrors[x.props.name]}
                            onErrorChange={this.onErrorChange}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="panel-content-row">
            <div className="panel-form-row">
              <div className="row">
                <div className="col-md-4 col-lg-5">
                  <h5 className="title-gray">{t('additionalInfo')}</h5>
                </div>
                <div className="col-md-12 col-lg-10">
                  <div className="panel-form-row row">
                    <Field
                      name="additionalInfo"
                      component="textarea"
                      placeholder={`${t('character.limit.2000')} (${t('optional')})`}
                      maxLength="2000"
                    />
                  </div>
                  <div className="panel-form-row panel-form-row-lg row">
                    <span className="col-gray length-countdown fl-right">
                      <span className="count">{additionalInfo?.length || 0}</span>
                      {` / `}
                      <span className="length">2000</span>
                    </span>

                    <div className="col-sm-8 col-lg-6 col-xl-4">
                      <button
                        type="submit"
                        disabled={
                          showAlertBanner ||
                          pristine ||
                          submitting ||
                          invalid ||
                          legacyPassword ||
                          this.getContactErrStatus()
                        }
                        onClick={handleSubmit}
                        className="button button-orange button-block mt-sm"
                      >
                        {t('button.save')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <ModalConsumer>
          {modalConsumer => {
            this.modalConsumer = modalConsumer
            return null
          }}
        </ModalConsumer>
      </Fragment>
    )
  }
}
